<template>
  <div class="card shadow-lg bg-gradient-secondary border-0 mb-0 w-75 justify-content-center p-5">

    <text-input
      @type="(val) => (password = val)"
      label="New Password"
      placeholder="Enter your new password"
      :is_password=true
    ></text-input>
     <text-input
      @type="(val) => (confirm_password = val)"
      label="Confirm New Password"
      placeholder="Confirm new password"
      :is_password=true
    ></text-input>
    <div class="text-warning" v-if="!valid">{{msg}}</div>

    <button @click="set_password" class="btn btn-default mt-2">Set password</button>
  </div>
</template>

<script>
import TextInput from "../../components/TextInput";
import { alert } from "../../utils/alertUtils";
import axios from "axios";
export default {
  name: "Set Password",
  components: {
    "text-input": TextInput,
  },
  data() {
    return {
      password: "",
      confirm_password:"",
      valid:true,
      msg:"",
    };
  },
  watch:{
     confirm_password(newValue){
       if(newValue!=this.password){
            this.valid=false
            this.msg="Passwords do not match"
       }else{
         this.valid=true
       }
     },
     password(newValue){
       if(newValue!=this.confirm_password){
            this.valid=false
            this.msg="Passwords do not match"
       }else{
         this.valid=true
       }
     }
  },
  methods: {

    set_password: function () {
      if(this.valid){
         axios
          .put(
            `${process.env.VUE_APP_BACKEND_URL}/api/set_password/${this.$route.query.tkn}`,
            {
              password: this.password,
              confirm_password: this.confirm_password,
             },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.getters.getToken,
                },
              }
          )
          .then(() => {
              alert.success_center("Password is changed, please you can login");
             this.$router.push("/login");
          })
          .catch((err) => {
             alert.error(err.response.data);
          });
      }else{
         this.msg="Passwords do not match" 
      }
      
    },
  },
};
</script>

<style>
</style>